import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
// import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyC9YkCylPAhDgUt-f-7SkG8TDHj8T0HK0U",

  authDomain: "jdm-network.firebaseapp.com",

  projectId: "jdm-network",

  storageBucket: "jdm-network.appspot.com",

  messagingSenderId: "987103931894",

  appId: "1:987103931894:web:dc767be4ecdd9a27772bd7",

  measurementId: "G-Y2HHHEBJ3E"

};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  // const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication};
